<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar" nzCollapsible nzWidth="200px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
    [nzTrigger]="null">
    <div class="sidebar-logo">
      <img [src]="isCollapsed ? '../../assets/images/antram-sm.png' : '../../assets/images/logo-antram-light.png'"
        alt="logo" />
    </div>

    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-menu-item nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? item.title : ''"
        [nzDisabled]="item.disabled" [nzMatchRouter]="true" [routerLink]="item.link" *ngFor="let item of menu">
        <span nz-icon [nzType]="item.icon"></span>
        <span>{{item.title}}</span>
      </li>
      <!-- <li nz-menu-item nz-tooltip nzTooltipPlacement="right"
        [nzTooltipTitle]="user?.isAntram ? 'Antram' : user?.isAdmin ? user?.company?.name  : 'XpertGo'" class="company">
        <span nz-icon nzType="build"></span>
        <span class="info">
           <small *ngIf="user?.isAdmin">Licenciado a</small>
          <div>{{user?.isAntram ? 'Antram' : user?.isAdmin ? user?.company?.name : 'XpertGo'}}</div>
          <small>V. {{version}}</small>
        </span>
      </li> -->
    </ul>

    <!-- <div class="company">
      <span nz-icon nzType="build"></span>
      <span class="info">
         <small *ngIf="user?.isAdmin">Licenciado a</small>
        <div>{{user?.isAntram ? 'Antram' : user?.isAdmin ? user?.company?.name : 'XpertGo'}}</div>
        <small>V. 0.0.0</small>
      </span>
    </div> -->
  </nz-sider>
  <nz-layout style="height: 100%; overflow: auto;">
    <nz-header>
      <!-- [ngClass]="user?.loginType == avaibleLogins.DEMO ? 'demoClass' : (user?.loginType == avaibleLogins.DEBT ? 'debtClass' : (user?.loginType == avaibleLogins.VIEW ? 'viewClass': '') )" -->
      <div class="app-header"
        [ngClass]="user?.loginType == avaibleLogins.DEMO ? 'demoClass' : (user?.loginType == avaibleLogins.DEBT ? 'debtClass' : (user?.loginType == avaibleLogins.VIEW ? 'viewClass': '') )">
        <div>
          <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <span class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></span>
          </span>
          <span *ngIf="user?.loginType == avaibleLogins.NONE" class="company-name">{{user?.isAntram ? 'Antram' :
            user?.isAdmin ? user?.company?.name :
            'XpertGo'}}</span>
        </div>
        <span *ngIf="user?.loginType == avaibleLogins.DEMO" class="demoText">Versão de Demonstração</span>
        <span *ngIf="user?.loginType == avaibleLogins.VIEW" class="viewText"> Modo Consulta</span>
        <span *ngIf="user?.loginType == avaibleLogins.DEBT" class="debtText"
          [title]="'Existem faturas por liquidar'"> <span nz-icon nzType="exclamation-circle"
            nzTheme="outline"></span> Existem faturas por liquidar</span>
        <div style="height: 64px;">
          <!-- <span class="header-trigger">
            <nz-badge [nzCount]="5">
              <span></span>
            </nz-badge>
          </span> -->
          <span class="header-trigger" (click)="profile()">
            <nz-avatar [nzText]="user | xUser:'initials'" [nzSrc]="user?.photo || ''"></nz-avatar>
            <span class="username">{{user | xUser:'username'}}</span>
          </span>
          <span class="header-trigger" (click)="logout()">
            <span class="trigger" nz-icon nzType="logout" nzTheme="outline"></span>
          </span>
        </div>
      </div>
    </nz-header>
    <nz-content class="content">
      <router-outlet></router-outlet>

      <!-- Privacy Modal -->
      <app-privacy-modal></app-privacy-modal>
    </nz-content>
    <nz-footer>
      <div class="footer">
        {{yearInfo}} | Desenvolvido por <a href="https://www.xpertgo.pt/">XpertGo</a> | V.{{version}}
      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>