import { Injectable } from '@angular/core';
import { UserRole } from '../models/auth.models';
import { MenuItem } from '../models/menu';
import { TachographStatus } from '../models/registration.models';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    protected authSrv: AuthService
  ) { }

  getMenu() {
    const user = this.authSrv.currentUser;
    let menu: MenuItem[] = [];
    if (user) {
      switch (user.role) {
        case UserRole.SUPERADMIN: {
          menu = [
            { title: 'Início', icon: 'home', link: '/home', disabled: false },
            { title: 'Administradores', icon: 'team', link: '/admins', disabled: false },
            { title: 'Utilizadores', icon: 'team', link: '/users', disabled: false },
            { title: 'Empresas', icon: 'build', link: '/companies', disabled: false },
            { title: 'Veículos', icon: 'car', link: '/vehicles', disabled: false },
            { title: 'Registos', icon: 'ordered-list', link: '/registrations', disabled: false },
            { title: 'Registos Inválidos', icon: 'unordered-list', link: '/error-registrations', disabled: false },
            { title: 'Licenças', icon: 'file-done', link: '/licenses', disabled: false },
            { title: 'Faturas', icon: 'file-done', link: 'invoices', disabled: false },
            // { title: 'Relatórios', icon: 'file', link: '/reports', disabled: true },
            { title: 'Erros', icon: 'bug', link: '/errors', disabled: false },
            { title: 'Logs', icon: 'solution', link: '/logs', disabled: false },
            { title: 'Configurações', icon: 'setting', link: '/configurations', disabled: false },
            { title: 'Configurações Sistema', icon: 'control', link: '/system-configurations', disabled: false },
            { title: 'Ajuda', icon: 'info-circle', link: '/help', disabled: false },
          ];
          break;
        }
        case UserRole.ANTRAM: {
          menu = [
            { title: 'Início', icon: 'home', link: '/home', disabled: false },
            { title: 'Administradores', icon: 'team', link: '/admins', disabled: false },
            { title: 'Empresas', icon: 'build', link: '/companies', disabled: false },
            { title: 'Utilizadores', icon: 'team', link: '/users', disabled: false },
            // { title: 'Licenças', icon: 'file-done', link: '/licenses', disabled: false },
            { title: 'Faturas', icon: 'file-done', link: 'invoices', disabled: false },
            { title: 'Configurações', icon: 'setting', link: '/configurations', disabled: false },
            { title: 'Configurações Sistema', icon: 'control', link: '/system-configurations', disabled: false },
            { title: 'Ajuda', icon: 'info-circle', link: '/help', disabled: false },
          ];
          break;
        }
        case UserRole.ADMIN: {
          menu = [
            { title: 'Início', icon: 'home', link: '/home', disabled: false },
            { title: 'Administradores', icon: 'team', link: '/admins', disabled: false },
            { title: 'Colaboradores', icon: 'team', link: '/users', disabled: false },
            { title: 'Veículos', icon: 'car', link: '/vehicles', disabled: false },
            { title: 'Registos', icon: 'ordered-list', link: '/registrations', disabled: false },
            { title: 'Licenças', icon: 'file-done', link: '/licenses', disabled: false },
            { title: 'Faturas', icon: 'file-done', link: 'invoices', disabled: false },
            // { title: 'Relatórios', icon: 'file', link: '/reports', disabled: true },
            { title: 'Ajuda', icon: 'info-circle', link: '/help', disabled: false },
          ];
          break;
        }
        // case UserRole.USER: {
        //   menu = [
        //     { title: 'Início', icon: 'home', link: '/home', disabled: false },
        //     { title: 'Registos', icon: 'unordered-list', link: '/registrations', disabled: false },
        //     { title: 'Ajuda', icon: 'info-circle', link: '/help', disabled: false },
        //   ];
        //   break;
        // }
        default: {
          menu = [
            { title: 'Início', icon: 'home', link: '/home', disabled: false },
            { title: 'Colaboradores', icon: 'team', link: '/users', disabled: false },
            { title: 'Veículos', icon: 'car', link: '/vehicles', disabled: false },
            { title: 'Registos', icon: 'unordered-list', link: '/registrations', disabled: false },
            { title: 'Licenças', icon: 'file-done', link: '/licenses', disabled: false },
            { title: 'Faturas', icon: 'file-done', link: 'invoices', disabled: false },
            // { title: 'Relatórios', icon: 'file', link: '/reports', disabled: true },
            { title: 'Ajuda', icon: 'info-circle', link: '/help', disabled: false },
          ];
          break;
        }
      }
    }

    return menu;
  }

  getRegistrationStatus() {
    return [
      // { value: TachographStatus.AUSENCIA, text: 'Ausência' },
      { value: TachographStatus.CONDUCAO, text: 'Condução' },
      { value: TachographStatus.DESCANSO, text: 'Descanso' },
      { value: TachographStatus.DISPONIBILIDADE, text: 'Disponibilidade' },
      // { value: TachographStatus.DISPONIBILIDADE_2, text: 'Disponibilidade (Condução em Pares)' },
      { value: TachographStatus.OUTRO_EMPREGADOR, text: 'Outro Empregador' },
      { value: TachographStatus.TRABALHO, text: 'Outros Trabalhos' },
      // { value: TachographStatus.TROCA_VEICULO, text: 'Troca de Veículo' },
      { value: TachographStatus.INICIO, text: 'Início de Serviço' },
      { value: TachographStatus.FIM, text: 'Fim de Serviço' },
      { value: TachographStatus.NR, text: 'Não Registado' },
    ];
  }

  getAdminRoles() {
    let roles: any[] = [];
    if (this.authSrv.currentUser?.isSuperAdmin) {
      roles = [
        { value: UserRole.SUPERADMIN, text: 'Administrador do Sistema' },
        { value: UserRole.ANTRAM, text: 'Antram' },
        { value: UserRole.ADMIN, text: 'Administrador de Empresa' },
      ];
    } else if (this.authSrv.currentUser?.isAntram) {
      roles = [
        { value: UserRole.ANTRAM, text: 'Antram' },
        { value: UserRole.ADMIN, text: 'Administrador de Empresa' },
      ];
    } else {
      roles = [
        { value: UserRole.ADMIN, text: 'Administrador de Empresa' },
      ];
    }
    return roles;
  }

  getColorScheme() {
    return [
      '#f5222d',
      '#fa541c',
      '#fa8c16',
      '#faad14',
      '#fadb14',
      '#a0d911',
      '#52c41a',
      '#13c2c2',
      '#1677ff',
      '#2f54eb',
      '#722ed1',
      '#eb2f96',
      '#bfbfbf'
    ];
  }
}
