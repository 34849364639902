import * as moment from "moment";
import { isArray } from "underscore";
import { User } from "./auth.models";
import { Company } from "./company.models";
import { CompanyUser } from "./companyUser.models";
import { Configuration } from "./configuration.models";
import { Vehicle } from "./vehicle.models";

export class Journey {
  id: string;
  vehicles: any[];
  date: Date | null;
  endDate: Date | null;
  notes: string;
  endNotes: string;
  place: string | null;
  endPlace: string | null;
  longitude: string | null;
  latitude: string | null;

  userId: string | null;
  companyId: string | null;

  company: Company | null;
  user: User | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.vehicles = $obj && $obj.vehicles ? $obj.vehicles : [];
    this.date = $obj && $obj.date ? new Date($obj.date) : null;
    this.endDate = $obj && $obj.endDate ? new Date($obj.endDate) : null;
    this.notes = $obj && $obj.notes ? $obj.notes : '';
    this.endNotes = $obj && $obj.endNotes ? $obj.endNotes : '';
    this.place = $obj && $obj.place ? $obj.place : null;
    this.endPlace = $obj && $obj.endPlace ? $obj.endPlace : null;
    this.longitude = $obj && $obj.longitude ? $obj.longitude : null;
    this.latitude = $obj && $obj.latitude ? $obj.latitude : null;

    this.userId = $obj && $obj.userId ? $obj.userId : $obj && $obj.user ? $obj.user.id : null;
    this.companyId = $obj && $obj.companyId ? $obj.companyId : $obj && $obj.company ? $obj.company.id : null;

    this.user = $obj && $obj.user ? new User($obj.user) : null;
    this.company = $obj && $obj.company ? new Company($obj.company) : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get duration() {
    if (this.endDate) {
      const endDate = moment(this.endDate);
      const minutes = endDate.diff(moment(this.date), 'minutes');
      const hours = minutes > 60 ? Math.floor(minutes / 60) : 0;
      const hoursText = hours < 10 ? `0${hours}` : hours;
      const endMinutes = (minutes - (hours * 60));
      const minutesText = endMinutes < 10 ? `0${endMinutes}` : endMinutes;
      return `${hoursText}:${minutesText}`;
    } else return 'N/A';
  }

  get scheduller() {
    const endDate = this.endDate ? moment(this.endDate) : moment();
    const isSameDate = moment(this.date).isSame(endDate, 'date');
    return isSameDate
      ? `${moment(this.date).format('DD-MM-YYYY')} ${moment(this.date).format('HH:mm')}-${endDate.format('HH:mm')}`
      : `${moment(this.date).format('DD-MM-YYYY HH:mm')} - ${endDate.format('DD-MM-YYYY HH:mm')}`;
  }

}
