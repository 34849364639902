import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'xCurrency',
  pure: false,
})
export class XCurrencyPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, currency: string): any {
    return formatCurrency(
      Number(value),
      'pt',
      getCurrencySymbol(currency, "narrow", 'pt'),
      '1.2-2');
  }

}
