import { User } from "./auth.models";

export enum LogType {
  EMAIL = 'EMAIL',
  EMAIL_INVALID = 'EMAIL_INVALID',
  SYNC = 'SYNC',
  EXPORT = 'EXPORT',
  USER = 'USER',
}

export class AppLog {
  id: number;
  date: Date;
  type: LogType;
  request: string | null;
  response: string | null;
  emailTo: string | null;
  emailContent: string | null;

  userId: string | null;
  userConsultedId: string | null;

  user: User | null;
  userConsulted: User | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.date = $obj && $obj.date ? new Date($obj.date) : new Date();
    this.type = $obj && $obj.type ? $obj.type : LogType.USER;
    this.request = $obj && $obj.request ? $obj.request : null;
    this.response = $obj && $obj.response ? $obj.response : null;
    this.emailTo = $obj && $obj.emailTo ? $obj.emailTo : null;
    this.emailContent = $obj && $obj.emailContent ? $obj.emailContent : null;
    
    this.userId = $obj && $obj.userId ? $obj.userId : $obj && $obj.user ? $obj.user.id : null;
    this.userConsultedId = $obj && $obj.userConsultedId ? $obj.userConsultedId : $obj && $obj.userConsulted ? $obj.userConsulted.id : null;
    
    this.user = $obj && $obj.user ? new User($obj.user) : null;
    this.userConsulted = $obj && $obj.userConsulted ? new User($obj.userConsulted) : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get typeDescription() {
    let description = '';

    switch (this.type) {
      case LogType.EMAIL: {
        description = 'E-mail'
        break;
      }
      case LogType.EMAIL_INVALID: {
        description = 'E-mail Inválidos'
        break;
      }
      case LogType.USER: {
        description = 'Utilizador';
        break;
      }
      case LogType.SYNC: {
        description = 'Sincronização';
        break;
      }
      case LogType.EXPORT: {
        description = 'Exportação';
        break;
      }
      default: {
        description = '';
        break;
      }
    }

    return description;
  }
}
