import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/service/auth.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { LoginType, User } from '../core/models/auth.models';
import { MenuItem } from '../core/models/menu';
import { DataService } from '../core/service/data.service';
import { environment } from 'src/environments/environment';
import moment from 'moment';
@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.less']
})
export class LayoutContainerComponent implements OnInit {
  isCollapsed = false;
  user: User | null = null;
  menu: MenuItem[] = [];
  version: string = environment.version;
  year: number = 2023;
  currentYear: number = moment().year();
  
  constructor(
    protected authSrv: AuthService,
    protected router: Router,
    protected dataSrv: DataService,
  ) {
    this.user = this.authSrv.currentUser;
  }
  get avaibleLogins() { return LoginType; }

  ngOnInit(): void {
    this.menu = this.dataSrv.getMenu();
  }

  get yearInfo() {
    return this.currentYear === this.year ? this.currentYear : `${this.year} - ${this.currentYear}`;
  }

  profile() {
    this.router.navigate(['/profile']);
  }

  logout() {
    this.authSrv.logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/auth/login']);
      });
  }
}
