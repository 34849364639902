import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            // console.warn('err', err);
            const ignore401 = request.headers.has('ignore401');
            if (err.status === 401 && !ignore401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                //location.reload();
            }

            const error = err.error.errors && err.error.errors.length > 0 ? err.error.errors[0].error : err.error.error ? err.error.error : err.statusText || err.statusText;
            return throwError(error);
        }));
    }
}
