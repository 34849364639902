import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/auth.models';
import { CompanyUser } from '../models/companyUser.models';
import { License } from '../models/license.models';
import { SystemConfiguration } from '../models/system-configuration.models';
import { Vehicle } from '../models/vehicle.models';

export enum PipeLicenseType {
  STATUS = 'status',
  COLOR = 'color'
}

@Pipe({
  name: 'xLicense',
  pure: false
})
export class XLicensePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, type?: any): string {
    let result: string;
    switch (type) {
      case PipeLicenseType.STATUS: {
        result = new License(value).statusDescription;
        break;
      }
      case PipeLicenseType.COLOR: {
        result = new License(value).statusColor;
        break;
      }
      default: {
        result = '';
        break;
      }
    }
    return result;
  }

}
