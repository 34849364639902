import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyModalComponent } from './privacy-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzBackTopModule } from 'ng-zorro-antd/back-top';

@NgModule({
  declarations: [
    PrivacyModalComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzIconModule,
    NzFormModule,
    NzModalModule,
    NzCheckboxModule,
    NzBackTopModule,
  ],
  exports: [
    PrivacyModalComponent
  ]
})
export class PrivacyModalModule { }
