import { Company } from "./company.models";
import { Configuration } from "./configuration.models";

export class Vehicle {
  id: number;
  licensePlate: string;
  brand: string | null;
  model: string | null;
  deleted: boolean;

  companyId: string;
  categoryId: string;

  company: Company | null;
  category: Configuration | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.licensePlate = $obj && $obj.licensePlate ? $obj.licensePlate : '';
    this.brand = $obj && $obj.brand ? $obj.brand : null;
    this.model = $obj && $obj.model ? $obj.model : null;
    this.deleted = $obj && $obj.deleted !== undefined ? $obj.deleted : false;
    
    this.companyId = $obj && $obj.companyId ? $obj.companyId : $obj && $obj.company ? $obj.company.id : null;
    this.categoryId = $obj && $obj.categoryId ? $obj.categoryId : $obj && $obj.category ? $obj.category.id : null;
  
    this.company = $obj && $obj.company ? new Company($obj.company) : null;
    this.category = $obj && $obj.category ? new Configuration($obj.category) : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }
}
