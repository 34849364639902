import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/auth.models';
import { CompanyUser } from '../models/companyUser.models';
import { Vehicle } from '../models/vehicle.models';

export enum PipeVehicleType {
  CATEGORY = 'category',
}

@Pipe({
  name: 'xVehicle',
  pure: false
})
export class XVehiclePipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, type?: any): string {
    let result: string;
    switch (type) {
      case PipeVehicleType.CATEGORY: {
        result = '';
        break;
      }
      default: {
        result = '';
        break;
      }
    }
    return result;
  }

}
