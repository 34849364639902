import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { first } from 'rxjs';
import { User } from 'src/app/core/models/auth.models';
import { ConfigurationType } from 'src/app/core/models/configuration.models';
import {
  SystemConfiguration,
  SystemConfigurationType,
} from 'src/app/core/models/system-configuration.models';
import { AuthService } from 'src/app/core/service/auth.service';
import { UsersService } from 'src/app/core/service/users.service';
import { isArray } from 'underscore';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SystemConfigurationService } from 'src/app/core/service/system-configuration.service';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.less'],
})
export class PrivacyModalComponent implements OnInit {
  @ViewChild('privacyPolicyElem') privacyPolicyElem: ElementRef | null = null;
  @ViewChild('content') content: ElementRef | null = null;

  currentUser: User | null = null;
  systemConfig: SystemConfiguration | null = null;

  showModal: boolean = false;
  loading: boolean = false;
  saving: boolean = false;
  acceptedPrivacy: boolean = false;
  disableSave: boolean = true;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected authSrv: AuthService,
    protected userSrv: UsersService,
    private notification: NzNotificationService,
    protected systemConfigSrv: SystemConfigurationService,
    protected nzModalSrv: NzModalService,
    protected router: Router
  ) {
    this.currentUser = this.authSrv.currentUser;
  }

  ngOnInit(): void {
    if (!this.currentUser?.privacyPolicyAccepted) {
      this.getSystemConfigInfo();
    }
  }

  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 500
    ) {
      this.disableSave = false;
    }
  }

  getSystemConfigInfo() {
    this.loading = true;
    this.systemConfigSrv
      .getByType(SystemConfigurationType.PRIVACY)
      .pipe(first())
      .subscribe(
        (res: any) => {
          this.systemConfig =
            res?.data && isArray(res.data) && res.data.length > 0
              ? new SystemConfiguration(res.data[0])
              : null;

          setTimeout(() => {
            if (
              this.privacyPolicyElem?.nativeElement.offsetHeight <
              this.content?.nativeElement.offsetHeight
            ) {
              this.disableSave = false;
            }
          }, 500);

          this.showModal = true;
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          console.error(error);
        }
      );
  }

  save() {
    this.saving = true;

    this.userSrv
      .acceptPrivacy()
      .pipe(first())
      .subscribe(
        (data: any) => {
          // console.warn('data', data);
          this.notification.create(
            'success',
            'Sucesso',
            'Política de privacidade aceite!'
          );
          this.saving = false;
          this.showModal = false;
        },
        (error: any) => {
          console.error(error);
          this.notification.create('error', 'Erro', error);
          this.saving = false;
        }
      );
  }

  logout() {
    this.authSrv
      .logout()
      .pipe(first())
      .subscribe(() => {
        this.router.navigate(['/auth/login']);
      });
  }
}
