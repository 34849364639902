import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {

  constructor(private authservice: AuthService) { }

  transform(hasPermission: string | string[]): boolean {
    return true;
    // if (hasPermission) {
    //   if (Array.isArray(hasPermission)) {
    //     let resp = false;
    //     hasPermission.forEach(permission => {
    //       if (this.authservice.hasPermission(permission)) {
    //         resp = true;
    //       }
    //     });

    //     return resp;
    //   } else {
    //     return this.authservice.hasPermission(hasPermission);
    //   }
    // } else return false;
  }

}
