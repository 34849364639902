import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/auth.models';
import { CompanyUser } from '../models/companyUser.models';
import { AppLog } from '../models/log.models';
import { SystemConfiguration } from '../models/system-configuration.models';
import { Vehicle } from '../models/vehicle.models';

export enum PipeLogType {
  TYPE = 'type',
}

@Pipe({
  name: 'xAppLog',
  pure: false
})
export class XAppLogPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, type?: any): string {
    let result: string;
    switch (type) {
      case PipeLogType.TYPE: {
        result = new AppLog(value).typeDescription;
        break;
      }
      default: {
        result = '';
        break;
      }
    }
    return result;
  }

}
