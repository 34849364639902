import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { XCurrencyPipe } from './x-currency.pipe';
import { HasPermissionPipe } from './has-permission.pipe';
import { XCompanyPipe } from './x-company.pipe';
import { XUserPipe } from './x-user.pipe';
import { XVehiclePipe } from './x-vehicle.pipe';
import { XRegistrationPipe } from './x-registration.pipe';
import { XSystemConfigPipe } from './x-system-configuration.pipe';
import { XAppLogPipe } from './x-app-log.pipe';
import { XLicensePipe } from './x-license';

@NgModule({
  declarations: [
    XCurrencyPipe,
    HasPermissionPipe,
    XCompanyPipe,
    XUserPipe,
    XVehiclePipe,
    XRegistrationPipe,
    XSystemConfigPipe,
    XAppLogPipe,
    XLicensePipe
  ],
  imports: [
    CommonModule
  ], 
  exports: [
    XCurrencyPipe,
    HasPermissionPipe,
    XCompanyPipe,
    XUserPipe,
    XVehiclePipe,
    XRegistrationPipe,
    XSystemConfigPipe,
    XAppLogPipe,
    XLicensePipe
  ]
})
export class PipesModule { }
