import { isArray } from 'underscore';
import { Company } from './company.models';
import { CompanyUser } from './companyUser.models';

export enum UserRole {
  SUPERADMIN = 'SUPERADMIN',
  ANTRAM = 'ANTRAM',
  ADMIN = 'ADMIN',
  USER = 'USER',
}
export enum LoginType {
  NONE = 'NONE',
  DEMO = 'DEMO',
  DEBT = 'DEBT',
  VIEW = 'VIEW',
}

export class User {
  id: string;
  name: string | null;
  email: string | null;
  nif: string | null;
  photo: string | null;
  role: UserRole;

  privacyPolicyAccepted: boolean;
  privacyDate: Date | null;

  canEditDeleteUsers: boolean;
  canApplyDiscounts: boolean;
  canCancelInvoices: boolean;
  issueLicensesWithoutInvoice: boolean;

  password: string;
  pin: string;
  token: string;
  expiresIn: Date | null;

  createdById: string | null;
  updatedById: string | null;
  companyId: string | null;
  companyUserId: string | null;

  createdBy: User | null;
  updatedBy: User | null;
  company: Company | null;
  companyUser: CompanyUser | null;
  companyUsers: CompanyUser[];
  loginType: LoginType;
  showAlertRenovation: boolean;

  lastLogin: Date | null;
  lastLogout: Date | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.name = $obj && $obj.name ? $obj.name : null;
    this.email = $obj && $obj.email ? $obj.email : null;
    this.nif = $obj && $obj.nif ? $obj.nif : null;
    this.photo = $obj && $obj.photo ? $obj.photo : null;
    this.role = $obj && $obj.role ? $obj.role : UserRole.USER;

    this.canEditDeleteUsers = $obj && $obj.canEditDeleteUsers != undefined ? $obj.canEditDeleteUsers : false;
    this.canApplyDiscounts = $obj && $obj.canApplyDiscounts != undefined ? $obj.canApplyDiscounts : false;
    this.canCancelInvoices = $obj && $obj.canCancelInvoices != undefined ? $obj.canCancelInvoices : false;
    this.issueLicensesWithoutInvoice =
      $obj && $obj.issueLicensesWithoutInvoice != undefined
        ? $obj.issueLicensesWithoutInvoice
        : false;

    this.privacyPolicyAccepted =
      $obj && $obj.privacyPolicyAccepted !== undefined
        ? $obj.privacyPolicyAccepted
        : false;
    this.privacyDate = $obj && $obj.privacyDate ? $obj.privacyDate : false;

    this.showAlertRenovation = $obj && $obj.showAlertRenovation ? $obj.showAlertRenovation : false;
    this.loginType = $obj && $obj.loginType ? $obj.loginType : null;
    this.password = $obj && $obj.password ? $obj.password : null;
    this.pin = $obj && $obj.pin ? $obj.pin : null;
    this.token = $obj && $obj.token ? $obj.token : null;
    this.expiresIn = $obj && $obj.expiresIn ? new Date($obj.expiresIn) : null;

    this.createdById =
      $obj && $obj.createdById
        ? $obj.createdById
        : $obj && $obj.createdBy
          ? $obj.createdBy.id
          : null;
    this.updatedById =
      $obj && $obj.updatedById
        ? $obj.updatedById
        : $obj && $obj.updatedBy
          ? $obj.updatedBy.id
          : null;
    this.companyId =
      $obj && $obj.companyId
        ? $obj.companyId
        : $obj && $obj.company
          ? $obj.company.id
          : null;
    this.companyUserId =
      $obj && $obj.companyUserId
        ? $obj.companyUserId
        : $obj && $obj.companyUser
          ? $obj.companyUser.id
          : null;

    this.createdBy = $obj && $obj.createdBy ? new User($obj.createdBy) : null;
    this.updatedBy = $obj && $obj.updatedBy ? new User($obj.updatedBy) : null;
    this.company = $obj && $obj.company ? new Company($obj.company) : null;
    this.companyUser =
      $obj && $obj.companyUser ? new CompanyUser($obj.companyUser) : null;
    this.companyUsers =
      $obj &&
        $obj.companyUsers &&
        isArray($obj.companyUsers) &&
        $obj.companyUsers.length > 0
        ? $obj.companyUsers.map((m: any) => new CompanyUser(m))
        : [];

    this.lastLogin = $obj && $obj.lastLogin ? new Date($obj.lastLogin) : null;
    this.lastLogout =
      $obj && $obj.lastLogout ? new Date($obj.lastLogout) : null;

    this.createdAt =
      $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt =
      $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get isSuperAdmin() {
    return this.role === UserRole.SUPERADMIN ? true : false;
  }
  get isAntram() {
    return this.role === UserRole.ANTRAM ? true : false;
  }
  get isAdmin() {
    return this.role === UserRole.ADMIN ? true : false;
  }
  get isUser() {
    return this.role === UserRole.USER ? true : false;
  }

  get username() {
    const nameSplit = this.name?.split(' ');
    return this.name
      ? nameSplit && nameSplit.length > 0
        ? nameSplit[0]
        : this.name
      : '';
  }

  get roleDescription() {
    let description: string;
    switch (this.role) {
      case UserRole.SUPERADMIN: {
        description = 'Administrador do Sistema';
        break;
      }
      case UserRole.ANTRAM: {
        description = 'Antram';
        break;
      }
      case UserRole.ADMIN: {
        description = 'Administrador';
        break;
      }
      case UserRole.USER: {
        description = 'Utilizador';
        break;
      }
      default: {
        description = 'Utilizador';
        break;
      }
    }
    return description;
  }

  get initials() {
    return (
      this.name
        ?.split(' ')
        .map((n: string) => n.substring(0, 1))
        .join('')
        .toUpperCase() || ''
    );
  }
}
