import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authSrv: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authSrv.currentUser;
    // console.warn('currentUser', currentUser);

    if (currentUser) {
      return true;
  } else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['auth/login'], { queryParams: { returnUrl: state.url } });
      return false;
  }
  }
}
