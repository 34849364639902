import { User } from "./auth.models";
import { Company } from "./company.models";
import { Configuration } from "./configuration.models";

export class CompanyUser {

  id: string;
  name: string | null;
  contact: string | null;
  birthDate: Date | null;
  workStartDate: Date | null;
  address: string | null;
  postalCode: string | null;
  active: boolean;

  userId: string | null;
  companyId: string | null;
  createdById: string | null;
  updatedById: string | null;
  userCategoryId: string | null;

  user: User | null;
  company: Company | null;
  createdBy: User | null;
  updatedBy: User | null;
  userCategory: Configuration | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.name = $obj && $obj.name ? $obj.name : null;
    this.contact = $obj && $obj.contact ? $obj.contact : null;
    this.birthDate = $obj && $obj.birthDate ? new Date($obj.birthDate) : null;
    this.workStartDate = $obj && $obj.workStartDate ? new Date($obj.workStartDate) : null;
    this.address = $obj && $obj.address ? $obj.address : null;
    this.postalCode = $obj && $obj.postalCode ? $obj.postalCode : null;
    this.active = $obj && $obj.active !== undefined ? $obj.active : false;

    this.userId = $obj && $obj.userId ? $obj.userId : $obj && $obj.user ? $obj.user.id : null;
    this.companyId = $obj && $obj.companyId ? $obj.companyId : $obj && $obj.company ? $obj.company.id : null;
    this.createdById = $obj && $obj.createdById ? $obj.createdById : $obj && $obj.createdBy ? $obj.createdBy.id : null;
    this.updatedById = $obj && $obj.updatedById ? $obj.updatedById : $obj && $obj.updatedBy ? $obj.updatedBy.id : null;
    this.userCategoryId = $obj && $obj.userCategoryId ? $obj.userCategoryId : $obj && $obj.userCategory ? $obj.userCategory.id : null;

    this.user = $obj && $obj.user ? new User($obj.user) : null;
    this.company = $obj && $obj.company ? new Company($obj.company) : null;
    this.createdBy = $obj && $obj.createdBy ? new User($obj.createdBy) : null;
    this.updatedBy = $obj && $obj.updatedBy ? new User($obj.updatedBy) : null;
    this.userCategory = $obj && $obj.userCategory ? new Configuration($obj.userCategory) : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }

  get addressComplete() {
    const addresses = [];
    if (this.address) addresses.push(this.address);
    if (this.postalCode) addresses.push(this.postalCode);

    return addresses.toString().replace(/,/g, ', ');
  }
}
