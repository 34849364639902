import { User } from "./auth.models";
import { Company } from "./company.models";
import { CompanyUser } from "./companyUser.models";

export enum ConfigurationType {
  VEHICLE = 'VEHICLE',
  USER = 'USER',
  CENTER_COST = 'CENTER_COST'
}

export class Configuration {
  id: string;
  designation: string;
  type: ConfigurationType;

  createdById: string | null;
  updatedById: string | null;

  createdBy: User | null;
  updatedBy: User | null;

  createdAt: Date;
  updatedAt: Date;

  constructor($obj?: any) {
    this.id = $obj && $obj.id ? $obj.id : '';
    this.designation = $obj && $obj.designation ? $obj.designation : null;
    this.type = $obj && $obj.type ? $obj.type : null;

    this.createdById = $obj && $obj.createdById ? $obj.createdById : $obj && $obj.createdBy ? $obj.createdBy.id : null;
    this.updatedById = $obj && $obj.updatedById ? $obj.updatedById : $obj && $obj.updatedBy ? $obj.updatedBy.id : null;
   
    this.createdBy = $obj && $obj.createdBy ? new User($obj.createdBy) : null;
    this.updatedBy = $obj && $obj.updatedBy ? new User($obj.updatedBy) : null;

    this.createdAt = $obj && $obj.createdAt ? new Date($obj.createdAt) : new Date();
    this.updatedAt = $obj && $obj.updatedAt ? new Date($obj.updatedAt) : new Date();
  }
}
